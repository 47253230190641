import React from 'react';
import logo from './logo.png';

function App() {
  return (
    <>
      <img src={logo} alt="Logo"/>
    </>
  )
}

export default App;
